import { Box, makeStyles, Typography, Button, Grid } from '@material-ui/core';
import CartItem from './CartItem';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart, removeFromCart } from '../../redux/actions/cartActions';
import TotalView from './TotalView';
import React from 'react';
import EmptyCart from './EmptyCart';
import CartAddr from './CartAddr';
import axios from 'axios';
//facebook pixlel
import ReactPixel from 'react-facebook-pixel';



const useStyle = makeStyles(theme => ({
    component: {
        // marginTop: 55,
        padding: '30px 135px',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            padding: '15px 0'
        }
    },
    leftComponent: {
        // width: '67%',
        paddingRight: 15,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 15
        }
    },
    header: {
        padding: '15px 24px',
        background: '#fff'
    },
    bottom: {
        padding: '16px 22px',
        background: '#fff',
        boxShadow: '0 -2px 10px 0 rgb(0 0 0 / 10%)',
        borderTop: '1px solid #f0f0f0'
    },
    addrcontinue:{
        width:"90%",
        margin:"auto",
        textAlign:'center',
        backgroundColor:"#ff9f00",
        fontSize:"18px",
        padding:"5px",
        color:"white",
        fontWeight:"500",
        marginBottom:"0px"
    },
    proceedtopay:{
        width:"90%",
        margin:"auto",
        textAlign:'center',
        backgroundColor:"#fb641b",
        fontSize:"18px",
        padding:"5px",
        color:"white",
        fontWeight:"500",
        marginBottom:"0px"
    }
}));

const Cart = ({ match, history }) => {

    
    const classes = useStyle();
    const cartDetails = useSelector(state => {
        return state.cart;
    });
    const { cartItems } = cartDetails;
   
    const dispatch = useDispatch();
   
    
    useEffect(() => {
        if(cartItems && match.params.id !== cartItems.id)   
            dispatch(addToCart(match.params.id));
    }, [dispatch, cartItems, match]);

    const removeItemFromCart = (id) => {
        dispatch(removeFromCart(id));
    }
   
    const gotoAddr=()=>{
        history.push("/cart/address");
    }

   
    //my Address
    const address=JSON.parse(localStorage.getItem("address")) || undefined;
    //for scrolling up
    window. scrollTo(0, 0);

    const totalAmount = () => {
        let pr = 0, dis = 0;
        cartItems.map(item => {
            pr += (item.price*item.quantity);
            dis += (item.discount*item.quantity);
        })
        return (+pr)+40;
    }

    const handlePayment=async ()=>{
        const data={
            name:address.name || "No name",
            mobile:address.phone,
            amount:totalAmount()
        }
        // const data={
        //     name:address.name || "No name",
        //     number:address.phone,
        //     amount:totalAmount(),
        //     MUID:"MUID"+Date.now(),
        //     transactionId:"T"+Date.now()
        // }

        ReactPixel.track('ProceedToPay', { 
            value: data.amount, // Example value of the transaction
            currency: 'INR', // Currency of the transaction
        });

       try{
            const response=await axios.post(`${process.env.REACT_APP_API_URL}/create-order`,data);
            console.log(response.data);
            window.location.href=response.data.url;
       }catch(err){
         console.log(err.data);
          console.log("err in payment");
       }
    }


    return (
        <>
        { cartItems.length ? 
            <Grid container className={classes.component}>
                
                <Grid item lg={9} md={9} sm={12} xs={12} className={classes.leftComponent}>
                    <Box> {address?<CartAddr address={address} /> :<Box/>} </Box>
                    <Box className={classes.header}>
                        <Typography style={{fontWeight: 600, fontSize: 18}}>My Cart ({cartItems?.length})</Typography>
                    </Box>
                        {   cartItems.map(item => (
                                <CartItem item={item} key={item.id}    removeItemFromCart={removeItemFromCart}/>
                            ))
                        }
                   
                </Grid> 
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <TotalView cartItems={cartItems} />
                </Grid>
               
               {
                 !address ? <Button variant="contained" onClick={gotoAddr} className={classes.addrcontinue} >Add Address And continue</Button>: <Button variant="contained"  onClick={handlePayment} className={classes.proceedtopay} >Proceed to pay</Button>
               }
            </Grid> : <EmptyCart />
        }
        </>

    ) 
}

export default Cart;