import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';

// FACEBOOK PIXEL CODE
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init('2804334443074837'); // Replace YOUR_PIXEL_ID with your Facebook Pixel ID
ReactPixel.pageView()

ReactDOM.render(
  <Provider store={store} >
      <App />
  </Provider>,
  document.getElementById('root') 
);

