
import { makeStyles, Typography, Box ,Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const useStyle = makeStyles({
    component: {
        width: '80%%',
        height: '65vh',
        background: '#fff',
        margin: "auto"
    },
    image: {
        width: "100%"
    },
    container: {
        textAlign: 'center',
        paddingTop: 70
    }
})


const EmptyCart = () => {
    const imgurl = 'https://rukminim2.flixcart.com/www/800/800/promos/16/05/2019/d438a32e-765a-4d8b-b4a6-520b560971e8.png?q=90';
    const classes = useStyle();
    const history=useHistory();

    return (
        <Box className={classes.component}>
            <Box className={classes.container}>
                <img src={imgurl} className={classes.image} alt="" />
                <Typography>Your cart is empty!</Typography>
                <span>Add items to it now.</span>
            </Box>
            <Button onClick={()=>{
                history.push("/")
            }} style={{backgroundColor:"#2874f0" ,color:"white" ,display:"flex", margin:"auto",marginTop:"50px"  }}  >Shop Now</Button>
        </Box>
    )
}

export default EmptyCart;