import * as actionTypes from '../constants/productConstant';
import axios from 'axios';
import 'dotenv/config'

export const getProducts = () => async (dispatch) => {
    try {
       // console.log(`${process.env.REACT_APP_API_URL}products`)
        const  {data}  = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
      
        dispatch({ type: actionTypes.GET_PRODUCTS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({ type: actionTypes.GET_PRODUCTS_FAIL, payload: error.response });
    }
};

export const getProductDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_REQUEST });
        
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}product/${id}`);
        
        dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_FAIL, payload: error.response});

    }
};


export const removeProductDetails = () => (dispatch) => {
    
    dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_RESET });

};
