import { Box,Button } from "@material-ui/core";

 const CartAddr= ({address} )=>{
    
    const {name,pin,phone,state,city,houseNo,area} = address;

    return (
        <Box style={{display:"flex",width:"90%",margin:"auto"}} >
            <Box style={{width:"80%"}} >
                <p style={{maxHeight:"23px",overflow:"hidden",lineHeight:"15px",fontSize:"15px"}} >Delever to : {name} ,{pin}</p>
                <p style={{color:"RGB(121 111 111)"}} >  {area}, {houseNo} </p>
            </Box>
            <Box style={{width:"20%"}} >
                <Button style={{color:"#2874f0"}}>Change</Button>
            </Box>
        </Box>
    )
}

export default CartAddr;