import axios from 'axios';

const url = process.env.REACT_APP_API_URL;
export const authenticateLogin = async (user) => {
    try {
        return  await axios.post(`${url}/login`, user) 
    } catch (error) {
        console.log('error while calling login API: ', error);
    }
}

export const authenticateSignup = async (user) => {
    try {
        return await axios.post(`${url}/signup`, user)
    } catch (error) {
        console.log('error while calling Signup API: ', error);
    }
}

export const getProductById = async (id) => {
    try {
        const res= await axios.get(`${url}/product/${id}`);
       // console.log(res);
        return res;
    } catch (error) {
        console.log('Error while getting product by id response', error);
    }
}


