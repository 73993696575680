import { Box } from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    images:{
        width:"100%"
    }
}))

const ProductReview =()=>{
    const classes = useStyles();
    const [data,setData]=React.useState({
          offerUrl:"https://i.ibb.co/M6Z1mm0/Whats-App-Image-2024-12-12-at-3-00-22-PM.jpg",
          ratingUrl:"https://i.ibb.co/FnNd8Kg/Whats-App-Image-2024-12-12-at-3-03-18-PM.jpg",
          reviewImages:[]
    }) 
    
    React.useEffect(()=>{
        


    },[])

    return (<>
      
         <Box>
            <Box className={classes.images} >
                    <img  style={{width:"100%"}} src={data.offerUrl} alt="" />
                    <img  style={{width:"100%"}} src={data.ratingUrl} alt="" />
                    {
                        data.reviewImages.map((img)=>{
                            return <img src={img} className={classes.images}/>
                        })
                    }
            </Box>


         </Box>
        
    </>)
}

export default ProductReview;