import { Box ,Typography,Grid,makeStyles ,Button} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyle = makeStyles(theme => ({

    deal: {
        display: 'flex',
        padding: '15px 20px'
    },
    dealText: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '32px',
        marginRight: 25
    },
    button: {
        marginLeft: 'auto',
        backgroundColor: '#2874f0',
        borderRadius: 2,
        fontSize: 13
    },
    all:{
        backgroundColor:"white"
    },
    wrapper: {
        padding: '25px 15px'
    },
    image: {
        width: 'auto',
        height: 150
    },
    text: {
        fontSize: 14,
        marginTop: 5
    },
    mygrid:{
        width:"90%",
        margin:"auto",
        display:"grid",
        gap:"13px",
        gridTemplateColumns:"3",
        gridTemplateRows:"auto"
    }
    
}))

const ProductsBanner=({title})=>{

    const classes=useStyle();

    const getProducts = useSelector(state => state.getProducts);
   
    const { products} = getProducts;

    return (
        <Box className={classes.all} >
            <Box className={classes.deal}>
                <Typography className={classes.dealText}>{title}</Typography>
                <Button variant="contained" color="primary" className={classes.button}>View All</Button>
            </Box>


            <Grid container  spacing={2} sx={{ padding: 2 }}>
      {products.map((temp) => (
        <Grid
          item
          xs={6} // 2 columns for mobile
          sm={3} // 4 columns for medium screens
          lg={2} // 6 columns for large screens
          key={temp.id}
        >
          <Link
            to={`product/${temp.id}`}
            style={{ textDecoration: "none" }}
          >
            <Box
            style={{border:"1px solid RGB(198 200 202)" ,borderRadius:"15px" ,height:"100%", overflow:"hidden" }}
              
              textAlign="center"
              sx={{
                padding: 3,
                borderRadius: 10,
                boxShadow: 1,
                backgroundColor: "#fff",
                "&:hover": {
                  boxShadow: 4,
                  transform: "scale(1.05)",
                  transition: "transform 0.3s ease-in-out",
                },
                height:"100%"
              
                 
              }}
            >
              <img
                src={temp.url}
                alt={temp.shortTitle}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: 150, // Maintain a consistent image height
                  objectFit: "contain",
                  margin:"10px",
                  marginBottom:"10px",
                  minHeight:"150px"
                }}
              />

             
                <Typography 
                component="span"
                variant="subtitle2"
                style={{ color: "green",fontSize:"16px" ,marginTop: "100px" }}
                >
                <Typography
                    component="span"
                    style={{
                    textDecoration: "line-through", // Strikethrough effect
                    marginRight: 8, // Add spacing between the two prices
                    color: "RGB(141 143 146)", // Optional: Different color for clarity
                    }}
                >
                    ₹{+temp.price + (+temp.discount)} {/* Strikethrough price */}
                </Typography>
                ₹{temp.price} {/* Original price */}
                </Typography>


              <Typography
                variant="subtitle1"
                style={{ fontWeight: 600, color: "#212121", marginTop: 8 }}
              >
                {temp.shortTitle}
              </Typography>

              <Typography
                variant="body2"
                style={{ color: "#212121", opacity: 0.6, marginTop: 4 }}
              >
                {temp.tagline}
              </Typography>
            </Box>
          </Link>
        </Grid>
      ))}
    </Grid>
            
        </Box>
    )

}

export default ProductsBanner;