import { Box, makeStyles ,Button} from "@material-ui/core";
import {TextField} from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import { Home ,Work } from "@material-ui/icons";
import React from "react";
import { useHistory } from 'react-router-dom';



const useStyle = makeStyles(theme => ({
    mainF:{
        width:"95%",
        margin:"auto",
        marginTop:"30px",
        border:"1px solid red"
    },
    inputs:{
        width:"100%",
        marginBottom:"20px"
    },
    addrcontinue:{
        width:"100%",
        margin:"auto",
        textAlign:'center',
        backgroundColor:"#fb641b",
        fontSize:"18px",
        padding:"5px",
        color:"white",
        fontWeight:"500",
        marginTop:"30px"
    }
}))


const Address=()=>{

    const history = useHistory();

    const classes=useStyle();
    const ref=React.useRef({
        name:"",
        pin:"",
        phone:"",
        state:"",
        city:"",
        houseNo:"",
        area:""
    });

    const handleInput=(e)=>{
         ref.current[e.target.name]=e.target.value;
    }
    
    const handleClick=(e)=>{
        localStorage.setItem( "address",JSON.stringify(ref.current) );
        history.push("/cart");
    }
    
    //for scrolling up
    window. scrollTo(0, 0);
    return(
        <Box style={{margin:"auto", paddingTop:"30px",width:"90%"}} >
          
                <TextField   onChange={(e)=>handleInput(e)} name="name" className={classes.inputs}   label="Full Name(Required)*" variant="outlined" />
                <TextField    onChange={(e)=>handleInput(e)} name="phone" type="number" className={classes.inputs}   label="Phone number(Required)*" variant="outlined" />
                
                <div style={{display:"flex"}} >
                    <TextField style={{width:"50%"}} onChange={(e)=>handleInput(e)} name="pin"  type="number" className={classes.inputs}   label="Pincode(Required)*" variant="outlined" />
                    <Button  style={{marginLeft:"20px",backgroundColor:"#2874f0" , color:"white",height:"45px"}}  > <LocationOn/> Use location</Button>
                </div>

                <div style={{display:"flex",justifyContent:"space-between"}} >
                    <TextField style={{width:"45%"}} onChange={(e)=>handleInput(e)} name="state"  className={classes.inputs}   label="State(Required)*" variant="outlined" />
                    <TextField style={{width:"45%"}} onChange={(e)=>handleInput(e)} name="city" className={classes.inputs}   label="City(Required)*" variant="outlined" />
                </div>

                <TextField onChange={(e)=>handleInput(e)} name="houseNo"  className={classes.inputs}   label="House No,Building Name (Required)*" variant="outlined" />
                <TextField onChange={(e)=>handleInput(e)} name="area"  className={classes.inputs}   label="Road Name,Area,Colony (Required)*" variant="outlined" />

                <p style={{color:"#2874f0",marginTop:"-10px"}} >+ Add Nearby Famous Shop/Mall/LandMark</p>

                <p style={{color:"RGB(142 145 148)" , fontSize:"15px"}}  >Type of Address</p>  
                <div>
                    <Button size="small" style={{padding:"6px",border:"1px solid RGB(142 145 148)",borderRadius:"10%",marginRight:"35px"}} > <Home style={{margin:"5px"}} /> Home</Button>
                    <Button size="small" style={{padding:"5px",padding:"6px",border:"1px solid RGB(142 145 148)",borderRadius:"10%"} } > <Work style={{margin:"5px"}} /> Work</Button>
                </div>

                <Button onClick={handleClick} variant="contained"  className={classes.addrcontinue} >
                   Save Address
                </Button>
        </Box>
        
    )

}

 export default Address;