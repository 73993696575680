import {
    CarouselProvider,
    Slide,
    Slider
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";

const IteamSlider = ({images}) => {
  return ( 
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={100}
      totalSlides={images.length}
      interval={2200}
      isPlaying={true}
    >
      <Slider>

        {
            images.map((url)=>{
                return (<Slide index={0}>
                    <img
                      style={{ maxHeight: "100%" }}
                      src={url}
                      alt=""
                    />
                  </Slide>)
            })
        }

      </Slider>
    </CarouselProvider>
  );
};

export default IteamSlider;
