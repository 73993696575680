import React, { useState } from "react";
import { ButtonGroup, Button, makeStyles } from "@material-ui/core";
import {changequantity} from "../../redux/actions/cartActions.js"
import { useDispatch } from 'react-redux';

const useStyle = makeStyles({
    component: {
        marginTop: 30
    },
    button :{
        borderRadius: '13%'
    }
})

const GroupedButton = ({id,count}) => {
    const classes = useStyle();
    const [ counter, setCounter ] = React.useState(count);
    const dispach=useDispatch();

    const handleChange= (num)=>{
        setCounter(counter => counter +num);
        dispach(changequantity(id,num));
    }

    return (
        <ButtonGroup className={classes.component} >
            <Button className={classes.button} onClick={() => handleChange(-1)} disabled={counter == 1}>-</Button>
            <Button disabled>{counter}</Button>
            <Button className={classes.button} onClick={() => handleChange(1)}>+</Button>
        </ButtonGroup>
    );
}

export default GroupedButton;