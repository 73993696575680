import * as actionTypes from '../constants/cartConstants';

export const cartReducer = (state = { cartItems: []}, action) => {
    switch(action.type) {
        case actionTypes.ADD_TO_CART:
            const item = action.payload;

            const existItem = state.cartItems.find(product => product.id === item.id);

            if(existItem){
                return {
                    ...state, cartItems: state.cartItems.map(x => x.product === existItem.product ? item : x)
                }
            } else {
                let a =  { ...state, cartItems: [...state.cartItems, item]}
                return a;
            }
        case actionTypes.REMOVE_FROM_CART:
      
            let s =  {
                ...state, cartItems: state.cartItems.filter(product => product.id !== action.payload)
            }
            return s;
        
        
        case actionTypes.CART_CHANGEQUANTITY:
            let id= action.payload.id;
            let qty= action.payload.qty;

            let st =  {
                ...state, cartItems: state.cartItems.map(product => {
                     if( product.id == id){
                        product.quantity+=qty;
                     }
                     return product;
                })
            }
            return st;

        default:
            return state;  
    }
}